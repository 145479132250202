  import React from "react";
  import {NavLink}  from 'react-router-dom';
 

  var logoStyle ={
  width:"140px",
  height:"140px",
  marginTop:"-45px"
  }

  
  function Navbar(){
    return (
        <>
     <div className="header header-secondary"  onClick={OpenSlide}><img  src="mylogo.png"  style={logoStyle} /></div>

     <div className="navb navb-secondary">
     <a className="bars"  onClick={OpenSlide}>&equiv;</a>
       <NavLink exact to ="/"   >Home</NavLink> 
       <NavLink exact to ="/projects">Projects</NavLink>
       <NavLink exact to ="/about">About</NavLink>
       <NavLink exact to ="/contact">Contact</NavLink> 
      
     </div>
     
     <div className="sidebar sidebar-secondary">
        <a className="cross" onClick={CloseSlide} >&times;</a>
        <NavLink exact to ="/" onClick={CloseSlide}   >Home</NavLink> 
       <NavLink exact to ="/projects"  onClick={CloseSlide}>Projects</NavLink>
       <NavLink exact to ="/about"  onClick={CloseSlide}>About</NavLink>
       <NavLink exact to ="/contact" onClick={CloseSlide}>Contact</NavLink> 
     </div>
    
     
       </> 
    )

    
    function OpenSlide(){
        if(window.screen.width > 799){
       document.querySelector(".sidebar").style.width="0px";      
                  }else{
         document.querySelector(".sidebar").style.width="300px";                                          
                  }
    
        
    }
    
    
     function CloseSlide(){
    document.querySelector(".sidebar").style.width="0px";    
        }  
    
  }



   
   
   export default Navbar;