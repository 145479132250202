import React from "react";
import imaj from "./img/james.jpg" ;
//import me from "./img/me.jpg" ;



function About(){
    return (
      <>
     
        <div className="container   about">
         <br/>
           
       <h3 className="abouttitle card" >About Me </h3>
             
          <br/><br/>
             <div className=" flex flex-3 profileInfo">
               <br/>
                 <img className="profile" src={imaj} alt="" widdth="100" height="100" />
                 <br/><br/>
                 <p className="fname">Firstname &nbsp;&nbsp;&nbsp;&nbsp;Jean F.</p><br/>
                 <p className="lname">Lastname  &nbsp;&nbsp;&nbsp;C&Eacute;R&Eacute;LUS</p>
               
              <br/><br/> <br/><br/>
          </div>
             
          
           
           
          <div className="aboutcontainer flex flex-3 " >
                 <p className="abouttext">
                          I have been a web developer since August 2018.<br/>
                          Precisely i started with Javascript as a programming language in March 2019.<br/><br/>
                          Since that period i've been working hard to become an experimented web developer <br/>
                          by creating multiple projects. <br/><br/>
                          As a web developer  i have a good interest in games development,<br/>
                          because by building games you learn twice. <br/><br/>
                          Because of that interest i have created my own Javascript 2d game library.<br/>
                  </p>

</div>

<div className="skills flex flex-3 " >
                <h3 className="currentskills card">My current skills are  :</h3>
                  <br/>
                   <div className="frontendbox">
                     <p className="frontend card">Front-end &darr;</p>
                   <p className="frontend-items">Html5 <br/> CSS3 <br/> Javascript <br/> Web Component <br/> Cordova CLI <br/> Html5 Canvas <br/>ElectronJs <br/> Git <br/> Some React .</p>
                   </div><br/>
                   <div className="backendbox">
                   <p className="backend card">Back-end &darr;</p>
                    <p className="backend-items">Some PHP <br/> NodeJs <br/> ExpressJs <br/> Mysql .</p>
                  </div>

</div>
<br/>
<br/>

<br/><br/>
      </div>
     
      </>
    )


   
    
    
  }
   
   
   
   export default About