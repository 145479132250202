 import React from "react";
 import {NavLink}  from 'react-router-dom';
 import imaj from "./img/james.jpg" ;
 //import me from "./img/me.jpg" ;
 
 const imageStyle = {
   width: "70%",
   height:"70%",
   borderRadius:"50%"
 
 };
 
 const textStyle = {
    fontSize:"15px",
    fontFamily:"arial ",
    lineHeight:"45px",
    color:"white"
  
 }
 
 const titleStyle = {
 color:"var(--secondary)"
 
 }



 
  
  function Home(){
    return (
        <div className="container cust">
        <br/>   <br/>  <br/>
        
       <div className=" flex sentence ">
       <h2 style={titleStyle}> Hi, <br/> i am Jean F. CERELUS</h2>
       <p style={textStyle}>This is my portfolio <br/>
         Where i am about to show my skills as a web dev.
       </p>
       <br/>
       <NavLink exact to ="/about"><button className="btn btn-secondary">Learn More</button></NavLink>
       </div>
       
        <div className=" flex image  card">
        <img src={imaj} alt="me" style={imageStyle}/>
       </div>
     
      </div>
    )


  
    
    
  }
   
   
   
   export default Home