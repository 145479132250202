import React from "react";

function Thankyou(){
    return (
     <div className="container">
          <center>
        <div className="thankscontainer">
   
    
    <h3 className="thanks">Thank you for your email</h3>
    </div>
   <br/><br/>

 <a className="redirect" href="https://www.jcerelusdev.tk/">Redirect to Home page</a>
   
   </center>

        </div>
        )

    }



    export default Thankyou