import React from "react";
  
  
function Footer(){
  return (
   <>
   <div className="footer footer-secondary">
     <div className="media">
     <span> <a href="https://www.facebook.com/jcerelus/"><i className="fab fa-facebook-f"></i></a></span>
     <span> <a href="https://www.instagram.com/jcerelus/"><i className="fab fa-instagram"></i></a></span>
     <span> <a href="https://www.youtu.be/GLWItLECgjg"><i className="fab fa-youtube"></i></a></span>
     <span> <a href="https://jcerelusdev.itch.io"><i className="fab fa-itch-io"></i></a></span>
     <span> <a href="https://www.github.com/JcerelusDev"><i className="fab fa-github"></i></a></span>
     </div>
    <br/>
   <p className="copywrite">&copy;JcerelusDev 2022</p>
   </div>
   
     </> 
  )

  
}
 
 
 
 export default Footer;