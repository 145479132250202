import React from "react";

function Contact(){
    return (
        <div className="container">
                <br/><br/> 
               <div className="form form-secondary">
                  <form  action="https://formsubmit.co/e306636959462a543e34ddb845694d6f"  target="_blank"  method="POST">
                     
                      <legend className="title">&rarr; Contact Me &larr;</legend>
                         <br/>
                         <input type="hidden" name="_subject" value="New Email has come !" />
                         <input type="hidden" name="_next" value="https://www.jcerelusdev.tk/thankyou" />
                         <input type="hidden" name="_next" value="https://www.jcerelusdev.tk/page/thankyou" />
                         <input className="text" type="text" placeholder="Your Full name" name="fullname" required /><br/>
                         <input className="email" type="email" placeholder="youremail@yhoo.fr" name="email" required  /><br/>
                       <textarea className="textarea" cols="20" rows="8"  name="message" placeholder="Your Message" required  ></textarea><br/>
                      <button className="btn btn-secondary" type="submit">Submit</button><br/><br/>
                     
                   </form>
              
           </div>
      
   
      </div>
    )


   
    
    
  }
   
   
   
   export default Contact