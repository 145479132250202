import React from "react";

function Projects(){
  return (
  

<div className="container proj">
<br/><br/>
<div className="flex flex-3 card items content0">
<h3>My_Todo </h3>
<br/><br/>
<p>Is an android app built  <br/> with web technologies <br/>
<strong> Js,Html,Css & Cordova. 
</strong><br/> Link : <a href="https://www.getjar.mobi/mobile/981824/MyTodo" rel="noreferrer" target="_blank">MyTodo</a>
</p>
</div>


<div className="flex flex-3 card items content1">
<h3>Quizzy-Game </h3>
<br/><br/>
<p>Is an android game built  <br/> with web technologies <br/> 
<strong> Js,Html,Css & Cordova.  
</strong> <br/> Link : <a href="https://www.getjar.mobi/mobile/981062/MyTodo" rel="noreferrer" target="_blank">Quizzy-Game</a>
</p>
</div>


<div className="flex flex-3 card items content2">
<h3>TestMy_Memory</h3>
<br/><br/>
<p>Is an android game built  <br/> with web technologies <br/>
<strong> Js,Html,Css & Cordova.  
</strong><br/> Link : <a href="https://www.getjar.mobi/mobile/983802/TestMyMemory" rel="noreferrer" target="_blank">TestMyMemory</a>
</p>
</div>




    
<div className="flex flex-3 card items content3">
    <h3>Color Finder </h3>
    <br/><br/>
    <p>Is an android app built <br/>  with web technologies <br/>
    <strong>Js,Html,Css & Cordova . 
   </strong><br/>  Link : <a href="https://www.getjar.mobi/mobile/984343/Find-hexadecimal-Color" rel="noreferrer" target="_blank">Color Finder</a>
    </p>
    </div>
    
    <div className="flex flex-3 card items content4">
    <h3> Tictactoe </h3>
    <br/><br/>
    <p>Is an android game built  <br/> with web technologies <br/>
<strong> Js,Html,Css & Cordova. 
</strong><br/> Link : <a href="https://www.getjar.mobi/mobile/984578/Tic-Tac-Toe" rel="noreferrer" target="_blank">Tictactoe</a>
    </p>
    
    </div>
    
    <div className="flex flex-3 card items content5" >
    <h3> CarRacing</h3>
    <br/><br/>
    <p>Is an android game built  <br/> with web technologies <br/>
<strong> Js,Html,Css & Cordova. 
</strong><br/> Link :  <a href="https://www.getjar.mobi/mobile/984332/CarRacing" rel="noreferrer" target="_blank">CarRacing</a>
    </p>
    </div>
    
    <div className="flex flex-3 card items content6">
    <h3> Snake Game </h3>
    <br/><br/>
    <p>Is an android game built  <br/> with web technologies <br/>
<strong> Js,Html,Css & Cordova. 
</strong><br/> Link : <a href="https://www.getjar.mobi/mobile/975019/Snake-Game" rel="noreferrer" target="_blank">Snake Game</a>
    </p>
    </div>
    
    <div className="flex flex-3 card items content7">
    <h3> Space Invaders </h3>
    <br/>
    <p>Is an android game built  <br/> with web technologies <br/>
<strong> Js,Html,Css & Cordova. 
</strong><br/> Link : <a href="https://www.getjar.mobi/mobile/984581/Space-invaders" rel="noreferrer" target="_blank">Space Invaders</a>
    
    </p>
    </div>
    
    <div className="flex flex-3 card items content8">
    <h3> Rock Paper Scissor </h3>
    <br/><br/>
    <p>Is an android game built  <br/> with web technologies <br/>
<strong> Js,Html,Css & Cordova. 
</strong><br/> Link : <a href="https://www.getjar.mobi/mobile/983251/RockPScissor" rel="noreferrer" target="_blank">Rock Paper Scissor</a>
    </p>
    </div>

    <div className="flex flex-3 card items content9">
    <h3> CanvasGameJs </h3>
    <br/><br/>
    <p>Is a javascript light weight <br/> 2d game library created to <br/>make javascript 
    canvas based  <br/> game much easier to develop.
<br/> Link : <a href="https://www.github.com/JcerelusDev/canvas00" rel="noreferrer" target="_blank">CanvasGameJs</a>
    </p>

    </div>
    
    <div className="flex flex-3 card items content10">
    <h3> GameDialogBoxJs </h3>
    <br/><br/>
    <p>Is a javascript  library  <br/> created to handle Dialog in  <br/>
    games but it can also used as <br/> a website modal  or popUp.
<br/> Link : <a href="https://www.github.com/JcerelusDev/GameDialogboxJs" rel="noreferrer" target="_blank"> GameDialogBoxJs</a>
    </p>

    
    </div>
    
    <div className="flex flex-3 card items content11">  
    <h3> SwiperJs </h3>
    <br/><br/>
    <p>Is a javascript  library  <br/> created to add swiper button <br/>
    as a controller for <br/>rpg or topdown style games.
<br/> Link : <a href="https://www.github.com/JcerelusDev/swiperJs" rel="noreferrer" target="_blank"> SwiperJs</a>
    </p>
 </div>
    

  
    <div className="flex flex-3 card items content12">
    <h3> ClassicUi </h3>
    <br/><br/>
    <p>Is a Css  library  <br/> created to reduce css line  <br/>
    of codes and web responsive pain.
<br/> Link : <a href="https://www.github.com/JcerelusDev/ClassicUi" rel="noreferrer" target="_blank"> ClassicUi</a>
    </p>
    </div>
    
    <div className="flex flex-3 card items content13">
    <h3> AjaxyJs </h3>
    <br/><br/>
    <p>Is a tiny javascript  library  <br/> created to make  <br/>
     ajax call much easier.<br/> with fewer lines of codes.
<br/> Link : <a href="https://www.github.com/JcerelusDev/Ajaxy" rel="noreferrer" target="_blank"> Ajaxy</a>
    </p>  
    </div>

   
    <div className="flex flex-3 card items content14">
    <h3> Toggle-Switch-Button </h3>
    <br/><br/>
    <p>Is a simple toggle button  <br/> project used as a custom <br/>
     checkbox which you can add some js to perform a task.
<br/> Link : <a href="https://www.github.com/JcerelusDev/Toggle-Switch-Button" rel="noreferrer" target="_blank"> Toggle-Switch-Button</a>
    </p>
    </div>
    
    <div className="flex flex-3 card items content15">
    <h3> Coffee_Corner </h3>
    <br/><br/>
    <p>Is cool coffee Shop website<br/> with cool effect. <br/>
      Build with color theory  . 
<br/> Link : <a href="https://coffeecornr.netlify.app/" rel="noreferrer" target="_blank"> Coffee_Corner</a>
    </p>  
    </div>
    

    


  </div>
    
    
    )


   
    
    
  }
   



  export default Projects;

























   
   
  