import React  from "react";
import {BrowserRouter as Router,Route} from "react-router-dom";
import './classicui.css';
import './App.css';
import './project.css';
import Home from "./Home"
import Navbar from  "./Navbar";
import Footer from "./Footer";
import Projects from "./Projects";
import About from "./About";
import Contact from "./Contact";
import Thankyou from "./Thankyou";

function App() { 


 
  return (
    <Router>
    <div>
   <Navbar />
   <Route exact path="/" component = {Home} />
   <Route exact path="/projects" component = {Projects} />
   <Route exact path="/about" component = {About} />
   <Route exact path="/contact" component = {Contact} />
   <Route exact path="/thankyou" component = {Thankyou} />
   <Footer />
    </div> 
    </Router>
  );
}

export default App;








